import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class TermsOfServices extends React.Component {

  render(){

    return(
      <Layout>

        <SEO title="Terms Of Services"/>
        <Link to="/" className="logo">Super!com</Link>
        
        <div className="text-page">
          <div className="center">

            <h2 className="text-page__title">Terms Of Services</h2>

            <h3 className="text-page__subtitle">WEBSITE TERMS OF USE</h3>

            <p className="text-page__paragraph -title">
              1. GENERAL
            </p>
            <p className="text-page__paragraph">
              <span className="text-page__callout">
                This section contains information about us.
              </span>
              1.1. This website is administered and operated by SUPER DOT COM LTD, with its registered office at 156a Burnt Oak Broadway Edgware Middlesex HA8 0AX, United Kingdom, (“We”, or “Super.Com”).
            </p>
            <p className="text-page__paragraph">
              1.2. Users are advised to read these Website Terms of Use (“Terms of Use”) before visiting or using the Website. These Terms of Use apply to the entire content of the Website, including information, functions, and services offered by PaySuper or other providers, as the case may be.
            </p>
            <p className="text-page__paragraph">
              <span className="text-page__callout">
                If you continue to use the Website, you must follow these terms of use.
              </span>
              1.3. Continued use of the Website and its services constitutes your consent to be bound by these Terms of Use. If you do not wish to accept and be bound by the Terms of Use, please, discontinue use of the Website, as well as its information, functions, and services.
            </p>
            <p className="text-page__paragraph">
              1.4. These Terms of Use may be amended and changed without prior notice at our sole discretion. The effective version of the Terms of Use is available to users at all times. Your continued use of the Website implies your acknowledgement and acceptance of any and all amendments and changes.
            </p>
            
            <p className="text-page__paragraph -title">
              2. CONTENT OF THE WEBSITE
            </p>
            <p className="text-page__paragraph">
              <span className="text-page__callout">
                The website's content is protected by intellectual property laws. You may not copy, modify, distribute, or otherwise use it without our consent.
              </span>
              2.1. All intellectual property rights, title, and interest in or to the Website's content, including copyrights, designs, patents, trademarks, trade names, and trade secrets, are owned by Super.Com or other third parties. Super.Com and respective third parties reserve all intellectual property rights to the Website's content. Note that these Terms of Use and access to the Website do not give you any rights, title, interest, or license in or to any intellectual property available on the Website. All purchases of licenses to digital content are subject to separate license agreements.
            </p>
            <p className="text-page__paragraph">
              <span className="text-page__callout">
                You may use our press kit available on the Website in certain purposes. If you need other permissions, please, contact <a href="mailto:marketing@supergg.com" className="text-page__link">marketing@supergg.com</a>.
              </span>
              2.2. Super.Com may make press kits available through the Website and / or external repository. Such press kits may contain texts, videos, images, logos and other materials.  You are free to view the press kit to learn more about Super.Com.
            </p>
            <p className="text-page__paragraph">
              Subject to the following terms and conditions, Super.Com grants you a non-sublicensable, non-transferable, non-exclusive, limited and revocable license to reproduce the elements of the press kit for non-commercial purposes in press releases, news and reviews only. If you reproduce any elements of the press kit, you shall accompany such copy by a copyright notice as set forth in Clause 2.3 and provide link this Website. You may not modify, alter or transform the elements of the press kit.
            </p>
            <p className="text-page__paragraph">
              The license is effective as long as the press kit and its respective elements are available on this Website and may be revoked by Super.Com at any time without prior notice.
            </p>
            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Copyright laws may allow you to use the Website's content for limited personal purposes. However, certain restrictions still apply in such cases.
              </span>
              2.3. If you are allowed to use the content of the Website without our consent and license under applicable intellectual property laws, you must accompany such use by a proper copyright notice that clearly and distinctly acknowledges: (i) Super.Com as the copyright owner, (ii) all modifications of the source content, and (iii) that your use of the source content is not connected with, sponsored, endorsed, or approved by Super.Com.
            </p>
            <p className="text-page__paragraph">
              <span className="text-page__callout">
                We respect the intellectual property of others. If anything is wrong, please send us a notice.
              </span>
              2.4. We respect the intellectual property rights of third parties. If you believe that your copyrighted work has been used unlawfully on the Website, you may notify us by sending a notice of an alleged infringement to our registered office as described in Section 10 of the End User License Agreement or to legal@supergg.com.
            </p>

            <p className="text-page__paragraph -title">
              3. DISCLAIMERS
            </p>
            <p className="text-page__paragraph">
              <span className="text-page__callout">
                Our website is available to everyone free of charge. It is therefore provided on an “as is” basis, and we have to disclaim any warranties and liability.
              </span>
              3.1. The Website and its content including press kits are provided on an “AS IS” and “AS AVAILABLE” basis. Super.Com hereby expressly disclaims any and all warranties and conditions of any kind, whether express, implied, or statutory, including all warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement, even in case of gross negligence. Super.Com makes no representations or warranties regarding the Website and its content, including the Website’s accuracy, completeness, availability, or timeliness. Information provided on the Website is subject to change.
            </p>
            <p className="text-page__paragraph">
              3.2. In no event should the content of the Website be construed as legal, tax-related, financial, or any other kind of advice. Any use of the information available on the Website is at your own discretion and risk, and you are solely responsible for any harm, damage, or other consequences that you suffer as a result of such use.
            </p>
            <p className="text-page__paragraph">
              3.3. The Website may contain links to third parties’ websites. We disclaim any responsibility for the content of any third-party websites and make no representations or warranties regarding the accuracy, timeliness, correctness, or legality of information, functions, and services provided by third-party websites. We do not endorse any products or services offered on or via third-party websites. All content and functionality of linked third-party websites are the sole responsibility of the third parties in question.
            </p>
            <p className="text-page__paragraph">
              3.4. In no event shall Super.Com be liable to you or any third party for any lost profits, lost data, costs of procurement of substitute products, or any indirect, consequential, exemplary, incidental, special, or punitive damages arising from or relating to these Terms of Use or your use of, or incapability to use, the Website, even if we have been advised of the possibility of such damages.  Access to and use of the Website is at your own discretion and risk, and you will be solely responsible for any damage to your device or computer system or loss of data resulting therefrom.
            </p>

            <p className="text-page__paragraph -title">
              4. GOVERNING LAW
            </p>
            <p className="text-page__paragraph">
              <span className="text-page__callout">
                This section explains a number of supplementary legal issues.
              </span>
              4.1. These Terms of Use and any dispute, claim, or obligation (whether contractual or non-contractual) arising out of or in connection with it or its subject matter or formation shall be governed by the laws of England and Wales without regard to its conflict of laws principles.
            </p>
            <p className="text-page__paragraph">
              4.2. In the event that any part of these Terms of Use is declared unlawful, void, or unenforceable under any applicable local law or by a competent court, that provision shall be stricken out; meanwhile, the remainder of these Terms of Use shall remain valid and enforceable.
            </p>

            <p className="text-page__paragraph -title">
              Date: November 28, 2019
            </p>

          </div>
        </div>

      </Layout>
    );

  }

}

export default TermsOfServices
